// src/components/layouts/Layout.js

import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Navbar from './Navbar';
import Footer from './Footer';
import ClientOnly from '../ClientOnly';
import WhatsAppBubble from '../WhatsappBubble';

/**
 * Cache control component that handles browser caching issues
 * Specifically targets mobile devices which have more aggressive caching
 * Doesn't rely on deprecated react-helmet
 */
const CacheControl = ({ children }) => {
  useEffect(() => {
    // Only run in browser environment
    if (typeof window === 'undefined') return;
    
    // Check if this is a mobile device
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
    
    // The issue primarily happens on mobile, so focus cache-busting there
    if (isMobile) {
      // Clear IndexedDB databases on page load
      if (window.indexedDB) {
        try {
          // Try to delete the known Gatsby databases
          window.indexedDB.deleteDatabase('keyval-store');
          
          // For browsers that support the databases() method
          if (window.indexedDB.databases) {
            window.indexedDB.databases().then(databases => {
              databases.forEach(db => {
                if (db.name.includes('keyval-store') || db.name.includes('gatsby')) {
                  console.log(`Clearing cache database: ${db.name}`);
                  window.indexedDB.deleteDatabase(db.name);
                }
              });
            }).catch(err => {
              console.warn('Error listing databases:', err);
            });
          }
        } catch (error) {
          console.warn('Error clearing IndexedDB:', error);
        }
      }
      
      // Handle page visibility changes to refresh content when returning to tab
      const handleVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
          // Check if the page has been hidden for a while (30+ seconds)
          const lastVisibleTime = parseInt(sessionStorage.getItem('lastVisibleTime') || '0', 10);
          const now = Date.now();
          
          if (lastVisibleTime && (now - lastVisibleTime > 30000)) {
            console.log('Page was hidden for over 30 seconds, reloading for fresh content');
            window.location.reload(true);
          }
          
          sessionStorage.setItem('lastVisibleTime', now);
        }
      };
      
      // Update last visible time when the component mounts
      sessionStorage.setItem('lastVisibleTime', Date.now());
      
      // Add visibility change listener
      document.addEventListener('visibilitychange', handleVisibilityChange);
      
      // Clean up the event listener when component unmounts
      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    }
  }, []);
  
  return children;
};

/**
 * Head component for the Layout - adds meta information to the document
 * Uses Gatsby's native Head API instead of Helmet
 * @param {Object} data - GraphQL data containing site settings
 */
export const Head = ({ data }) => {
  // Generate a unique timestamp for cache-busting
  const timestamp = new Date().toISOString();
  
  return (
    <>
      <html lang="en-ZA" />
      <title>{data?.sanitySiteSettings?.title || 'UniCollege West Rand'}</title>
      <link rel="icon" href="/icon.png"/>
      
      {/* Cache control meta tags */}
      <meta httpEquiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
      <meta httpEquiv="Pragma" content="no-cache" />
      <meta httpEquiv="Expires" content="0" />
      
      {/* Add a unique timestamp as a meta tag to ensure fresh content */}
      <meta name="generated-time" content={timestamp} />
    </>
  );
};

/**
 * Main Layout component that wraps all pages
 * Now includes cache-busting functionality for mobile devices
 */
const Layout = ({ children, location }) => {
  // Static query for layout data
  const data = useStaticQuery(graphql`query LayoutQuery {
    allSanityLearningMethod(sort: {rank: ASC}) {
      nodes {
        title
        slug {
          current
        }
      }
    }
    allSanityStudyField(sort: {title: ASC}) {
      nodes {
        title
        slug {
          current
        }
      }
    }
    sanityCompanyInfo {
      address
      email
      telephoneNumber
      whatsappNumber
      socialMediaLinks {
        facebook
        displayFacebook
        instagram
        displayInstagram
        youtube
        displayYoutube
        linkedin
        displayLinkedin
      }
    }
    sanitySiteSettings {
      title
      quickLinks {
        title
        slug {
          current
        }
        url
      }
    }
  }`);

  // Safely handle Sanity data which might be null/undefined
  const allLearningMethods = data?.allSanityLearningMethod?.nodes || [];
  const allStudyFields = data?.allSanityStudyField?.nodes || [];
  
  // Safely destructure company info
  let socialMediaLinks = null;
  let contactInfo = null;
  
  if (data?.sanityCompanyInfo) {
    socialMediaLinks = data.sanityCompanyInfo.socialMediaLinks || null;
    contactInfo = {
      address: data.sanityCompanyInfo.address,
      email: data.sanityCompanyInfo.email,
      telephoneNumber: data.sanityCompanyInfo.telephoneNumber,
      whatsappNumber: data.sanityCompanyInfo.whatsappNumber
    };
  }
  
  const quickLinks = data?.sanitySiteSettings?.quickLinks || [];

  // Use effect hook to add cache control headers dynamically
  useEffect(() => {
    if (typeof document !== 'undefined') {
      // Correctly add meta tags for cache control
      const addMetaTag = (httpEquiv, content) => {
        // Check if it already exists
        let meta = document.querySelector(`meta[http-equiv="${httpEquiv}"]`);
        if (!meta) {
          meta = document.createElement('meta');
          meta.setAttribute('http-equiv', httpEquiv);
          meta.setAttribute('content', content);
          document.head.appendChild(meta);
        }
      };
      
      const addNamedMetaTag = (name, content) => {
        // Check if it already exists
        let meta = document.querySelector(`meta[name="${name}"]`);
        if (!meta) {
          meta = document.createElement('meta');
          meta.setAttribute('name', name);
          meta.setAttribute('content', content);
          document.head.appendChild(meta);
        }
      };
      
      // Add cache control meta tags
      addMetaTag('Cache-Control', 'no-cache, no-store, must-revalidate');
      addMetaTag('Pragma', 'no-cache');
      addMetaTag('Expires', '0');
      
      // Add a unique timestamp for cache busting
      addNamedMetaTag('generated-time', Date.now().toString());
    }
  }, []);

  return (
    <CacheControl>
      <Navbar 
        learningMethods={allLearningMethods} 
        studyFields={allStudyFields} 
        location={location} 
      />
      <main>{children}</main>
      <Footer 
        learningMethods={allLearningMethods} 
        studyFields={allStudyFields} 
        socialMediaLinks={socialMediaLinks} 
        quickLinks={quickLinks} 
        contactInfo={contactInfo} 
      />
      {/* WhatsApp bubble only rendered on client-side */}
      {contactInfo?.whatsappNumber && (
        <ClientOnly>
          <WhatsAppBubble number={contactInfo.whatsappNumber} />
        </ClientOnly>
      )}
    </CacheControl>
  );
};

export default Layout;