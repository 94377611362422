import React from 'react'
import { FaWhatsapp } from 'react-icons/fa'

const WhatsAppLink = ({ number, showIcon = true }) => {
if (!number) return null

const formattedNumber = number.replaceAll(' ', '')

const handleWhatsAppClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    window.open(`https://wa.me/+27${formattedNumber}`, '_blank')
}

return (
    <>
    {showIcon && <FaWhatsapp />}
    <button
        onClick={handleWhatsAppClick}
        className='bg-none border-none text-inherit p-0 font-inherit cursor-pointer no-underline transition-colors hover:text-red-500'
        aria-label={`Chat on WhatsApp ${number}`}
    >
        {number}
    </button>
    </>
)
}

export default WhatsAppLink