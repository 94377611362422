// gatsby-browser.js

// Import React and components
import React from "react"
import Layout from "./src/components/layouts/Layout"
import ErrorBoundary from "./src/components/ErrorBoundary"

// Import font files
import "@fontsource/montserrat"
import "@fontsource/montserrat/100.css"
import "@fontsource/montserrat/200.css"
import "@fontsource/montserrat/300.css"
import "@fontsource/montserrat/500.css"
import "@fontsource/montserrat/600.css"
import "@fontsource/montserrat/700.css"
import "@fontsource/montserrat/800.css"
import "@fontsource/montserrat/900.css"

// Import global styles
import './src/styles/global.scss'

/**
 * Force unregister any existing service workers when the page initially renders
 * This helps prevent cached content from being served
 */
export const onInitialClientRender = () => {
  if (typeof navigator !== 'undefined' && 'serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(registrations => {
      for (let registration of registrations) {
        console.log('Unregistering service worker:', registration);
        registration.unregister();
      }
    }).catch(err => {
      console.warn('Error unregistering service workers:', err);
    });
  }
  
  // Also completely clear cache storage
  if (typeof caches !== 'undefined') {
    caches.keys().then(cacheNames => {
      cacheNames.forEach(cacheName => {
        console.log('Deleting cache:', cacheName);
        caches.delete(cacheName);
      });
    }).catch(err => {
      console.warn('Error clearing caches:', err);
    });
  }
};

/**
 * When Gatsby loads, clear any stale data from IndexedDB
 * and local storage to prevent serving cached content
 */
export const onClientEntry = () => {
  if (typeof window === 'undefined') return
  
  // Run in both development and production to ensure clean state
  // Try to clear all IndexedDB databases
  if (window.indexedDB) {
    try {
      // For browsers that support the databases() method
      if (window.indexedDB.databases) {
        window.indexedDB.databases().then(databases => {
          databases.forEach(db => {
            if (db.name.includes('keyval-store') || db.name.includes('gatsby')) {
              console.log(`Clearing stale database: ${db.name}`)
              window.indexedDB.deleteDatabase(db.name)
            }
          })
        }).catch(err => {
          console.warn('Error listing databases:', err)
        })
      } else {
        // For browsers that don't support databases()
        // Try to delete the known Gatsby databases
        window.indexedDB.deleteDatabase('keyval-store')
        window.indexedDB.deleteDatabase('gatsby-browser-cache')
      }
    } catch (error) {
      console.warn('Error clearing IndexedDB:', error)
    }
  }
  
  // Clear localStorage items related to Gatsby
  if (window.localStorage) {
    try {
      Object.keys(window.localStorage).forEach(key => {
        if (key.includes('gatsby') || key.includes('sw-precache')) {
          window.localStorage.removeItem(key)
        }
      })
    } catch (error) {
      console.warn('Error clearing localStorage:', error)
    }
  }
}

/**
 * Wraps every page with ErrorBoundary and Layout
 * Using named imports is more reliable than requires for React components
 */
export const wrapPageElement = ({ element, props }) => {
  return (
    <ErrorBoundary>
      <Layout {...props}>{element}</Layout>
    </ErrorBoundary>
  )
}

/**
 * Called when the service worker updates
 * Force reload immediately
 */
export const onServiceWorkerUpdateReady = () => {
  if (typeof window !== 'undefined') {
    console.log('Service worker update detected, forcing reload');
    window.location.reload(true)
  }
}

/**
 * Clean up resources when route changes
 * Uses safe browser API checks
 */
export const onRouteUpdate = () => {
  if (typeof window !== 'undefined' && window.requestIdleCallback) {
    window.requestIdleCallback(() => {
      // Perform any cleanup when browser is idle
      if (window.previousObservers) {
        Object.values(window.previousObservers).forEach(observer => {
          if (observer && typeof observer.disconnect === 'function') {
            observer.disconnect()
          }
        })
        window.previousObservers = {}
      }
    })
  }
}