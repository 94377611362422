import React from 'react'
import styled from 'styled-components'
import { FaWhatsapp } from 'react-icons/fa'

const WhatsAppBubble = ({ number }) => {
  if (!number) return null

  // Format number once to avoid repeated calculations
  const formattedNumber = number.replaceAll(' ', '')
  const whatsappUrl = `https://wa.me/+27${formattedNumber}`

  const handleWhatsAppClick = (e) => {
    // Prevent all default behaviors
    e.preventDefault()
    e.stopPropagation()
    
    // Single window action - just open in new tab
    window.open(whatsappUrl, '_blank')
  }

  return (
    <StyledBubble
      onClick={handleWhatsAppClick}
      // Remove href to prevent any default link behavior
      role="button"
      aria-label="Chat on WhatsApp"
    >
      <FaWhatsapp />
    </StyledBubble>
  )
}

// Change from anchor to button for better semantic meaning
const StyledBubble = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25D366;
  color: white;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 6px rgba(0,0,0,0.4);
  transition: transform 0.2s;
  z-index: 1000;
  cursor: pointer;
  padding: 0;
  
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  svg {
    font-size: 2.5em;
    margin: 0;
  }

  &:hover {
    transform: scale(1.1);
    color: white;
  }

  @media (max-width: 480px) {
    width: 50px;
    height: 50px;
    
    svg {
      font-size: 2em;
    }
  }
`

export default WhatsAppBubble