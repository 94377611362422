// src/components/ClientOnly.js

import { useState, useEffect } from 'react';

/**
 * ClientOnly component that renders its children only on the client side
 * Prevents hydration errors by ensuring content only renders in browser
 * 
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - Content to render on client side
 * @param {React.ReactNode} props.placeholder - Content to show during initial render (server-side)
 * @returns {React.ReactNode} - Either the placeholder or the children once mounted
 */
const ClientOnly = ({ children, placeholder = null }) => {
  // Only track mounted state, no references needed for this simple case
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    // Set mounted state to true after component mounts
    // No setTimeout needed - this is more reliable
    setHasMounted(true);
    
    // No cleanup needed for this simple state update
  }, []);

  // Return placeholder until mounted
  return hasMounted ? children : placeholder;
};

export default ClientOnly;